import { ALL_ROLES_IDS } from "../globals/const";

class StorageServiceClass {
  getUser() {
    const name = sessionStorage.getItem("name");
    const sid = sessionStorage.getItem("sid");
    const role_id = sessionStorage.getItem("role_id");
    const contributor_id = sessionStorage.getItem("contributor_id");
    const contributor_type = sessionStorage.getItem("contributor_type");
    const contributor_name = sessionStorage.getItem("contributor_name");
    const is_root = sessionStorage.getItem("is_root");
    const ms_sso = sessionStorage.getItem("ms_sso");
    const password_change = sessionStorage.getItem("password_change");
    const rolesString = sessionStorage.getItem("roles");
    const full_facts = sessionStorage.getItem("full_facts");
    const roles = JSON.parse(rolesString);
    return {
      name,
      sid,
      role_id,
      contributor_id,
      contributor_type,
      contributor_name,
      is_root,
      ms_sso,
      password_change,
      roles,
      full_facts,
    };
  }

  getSid() {
    return sessionStorage.getItem("sid");
  }

  setUser(user) {
    const {
      name,
      sid,
      role_id,
      contributor_id,
      contributor_type,
      is_root,
      contributor_name = "name",
      password_change,
      ms_sso,
      full_facts,
    } = user;
    const hasRoles = ALL_ROLES_IDS.filter((id) => {
      return (role_id & id) === id;
    });
    sessionStorage.setItem("roles", JSON.stringify(hasRoles));
    sessionStorage.setItem("name", name);
    sessionStorage.setItem("sid", sid);
    sessionStorage.setItem("role_id", role_id);
    sessionStorage.setItem("contributor_id", contributor_id);
    sessionStorage.setItem("contributor_type", contributor_type);
    sessionStorage.setItem("contributor_name", contributor_name);
    sessionStorage.setItem("is_root", is_root);
    sessionStorage.setItem("ms_sso", ms_sso);
    sessionStorage.setItem("password_change", password_change);
    sessionStorage.setItem("full_facts", full_facts);

    return true;
  }

  removeUser() {
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("sid");
    sessionStorage.removeItem("role_id");
    sessionStorage.removeItem("contributor_id");
    sessionStorage.removeItem("contributor_type");
    sessionStorage.removeItem("password_change");
    sessionStorage.removeItem("ms_sso");
    sessionStorage.removeItem("roles");
    sessionStorage.removeItem("is_root");
    sessionStorage.removeItem("contributor_name");
    sessionStorage.removeItem("full_facts");
    sessionStorage.removeItem("bol-joyride-status");
    sessionStorage.removeItem("joyride-status");
    sessionStorage.removeItem("source-joyride-status");
  }
}

const StorageService = new StorageServiceClass();

export default StorageService;
