export const CORE_USER_ROLES = {
  128: "Admin",
  255: "Buyer",
  224: "Seller",
};
export const SeverityLevel = {
  1: "info",
  2: "warning",
  3: "error",
  4: "success",
};

export const SeverityColors = {
  1: "blue",
  2: "orange",
  3: "red",
  4: "green",
};

export const ALL_ROLES_IDS = [1, 2, 4, 8, 16, 32, 64, 128];

export const requiredRolesForNotification = [1, 2, 32]; // Array of role IDs to check for notifications
export const requiredRolesForAdmin = [128]; // Array of role IDs to check for admin
export const requiredRolesForSources = [4, 16]; // Array of role IDs to check for sources
export const requiredRolesForBol = [8, 32, 64]; // Array of role IDs to check for BOL's
export const requiredRolesForAudits = [2, 64]; // Array of role IDs to check for Audits's

//permission Roles
export const requiredRolesForCreateBol = [32];
export const requiredRolesForSearchBol = [8];
export const requiredRoleForAuditor = [64];
export const requiredRoleForUploadSources = [16];
export const requiredRoleForSearchSources = [4];
export const requiredRoleForShowAttachments = [64, 8];
export const REACT_APP_HIDE_LOGIN_SIGNUP = true;

export const REACT_APP_INVITE_BASE_URL = "https://stg.facts.aegex.com/register";
